import "../styles/contact.css"

export default function Contact(){
  return (
    <>
    <div className="contact-container" id="contact">
        <h1></h1>
        <div className="contact-section">
          <p>thomas_vr@hotmail.nl</p>
          <p>{"+31681337813 "}</p>
          <p>{'Amsterdamsestraatweg, Utrecht 3553EL, NL'}</p>
        </div>
      </div>
    </>
  )
}