import TextCard from "./components/TextCard";
import Header from "./components/Header/Header";
import "./styles/introCard.css";
import "./styles/projects.css";
import Projects from "./components/Projects";
import "./styles/general.css";
import "./styles/videoTransition.css";
import {useRef, useState, useEffect} from "react";
import SetVisibility from "./functionality/SetVisiblility";
import Contact from "./components/Contact";
import About from "./components/About";
import MobileHeaderButton from "./components/Header/MobileHeaderButton";

export default function Front() {

  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);
  const [mobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    if(window.matchMedia("(max-width: 560px").matches){
      setMobileMenu(true);
    } else {
      setMobileMenu(false);
    }
  }, [])

  useEffect(() => {
    
    const handleResize = () => {
      if(window.matchMedia("(max-width: 560px").matches){
        setMobileMenu(true);
      } else {
        setMobileMenu(false);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  return (
    <>
      {mobileMenu ? <MobileHeaderButton /> : <Header />}
        <a href={"#projects"}>
        <SetVisibility containerRef={containerRef} isvisible={isVisible} setIsVisible={setIsVisible} reset={true} />   
          <div className={`middle fade-in-mouse${isVisible ? "-appear" : ""}`} ref={containerRef}>
            <div className="mouse">
            </div>
          </div>
        </a>
      <div className="front" id="intro">
        <div className="front-intro">
          <TextCard
            isShiny
            text={
              <>
              <div className="intro-text">
                {<h1>{"<Intro />"}</h1>}
                <p> Hi, my name is</p>
                <p className="intro-name">Thomas van Rossum</p>
                <p className="intro-outro">, and I'm a developer</p>
                </div>
              </>
            }
          />
        </div>
        <div className="divider"/>

        <Projects />
        <About />
        <Contact />
      </div>
    </>
  );
}