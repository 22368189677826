export default function ProjectDisplayCard({ title, text, date, link, setVideoLink, videoLink, target, isVisible, setShowVid, setVideoText, setVideoTitle, link2, linktext}) {

  const callbackFunction = () => {
    setVideoLink(videoLink);
    setShowVid(true);
    setVideoText(text);
    setVideoTitle(title)
  }

  return (
    <>
      <div className={`hover-card project-card fade-in${isVisible ? "-appear" : ""}`}>
      <a href={link} target={target}>
        <div  onClick={callbackFunction} className={"top-card"}>
        {<h3>{title}</h3>}
        </div>      
        <div className="project-content">

          <p  className="project-text" onClick={callbackFunction}>{text + "\n\n"}
          </p>
          <a className="project-git-link" href={link2} target={"_blank"} rel="noreferrer">{linktext}</a>          
          <div className="project-date">{date}</div>
        </div>
        </a>
      </div>
    </>
  );
}
