import "../styles/about.css";
import unityIcon from "../images/unity-icon.png";
import csharpIcon from "../images/csharp_icon.png";
import jsIcon from "../images/js.png";
import cssIcon from "../images/CSS3.png";
import fmodIcon from "../images/FMOD-icon.png";
import htmlIcon from "../images/HTML5.png";
import wwiseIcon from "../images/wwise-logo.png";
import cppIcon from "../images/cpp_icon.png"

import SetVisibility from "../functionality/SetVisiblility";

import { useRef, useState } from "react";

export default function About(){
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);
  return(

    <div className="about-container" id="about">
    <SetVisibility containerRef={containerRef} isvisible={isVisible} setIsVisible={setIsVisible} reset={true}/>
    <h1>{"<About />"}</h1>
    <div ref={containerRef} className="about-section">
      <div className="about-left-section">
        <div className="picture-carousel">
          <div className={`logo${isVisible ? " appear" : ""}`}>
            <img  src={unityIcon} alt="Unity"></img>
          </div>
          <div className={`logo${isVisible ? " appear" : ""}`}>
            <img  src={csharpIcon} alt="Csharp"></img>
          </div>
          <div className={`logo${isVisible ? " appear" : ""}`}>
            <img  src={cppIcon} alt="CPlusPlus"></img>
          </div>
          <div className={`logo${isVisible ? " appear" : ""}`}>
            <img  src={jsIcon} alt="Javascript"></img>
          </div>
          <div className={`logo${isVisible ? " appear" : ""}`}>
            <img  src={htmlIcon} alt="HTML"></img>
          </div>
          <div className={`logo${isVisible ? " appear" : ""}`}>
            <img  src={cssIcon} alt="CSS"></img>
          </div>
          <div className={`logo${isVisible ? " appear" : ""}`}>
            <img  src={fmodIcon} alt="FMOD"></img>
          </div>
          <div className={`logo${isVisible ? " appear" : ""}`}>
            <img  src={wwiseIcon} alt="Wwise"></img>
          </div>

        </div>
      </div>
      <div className="about-right-section">
        <h3>Hello there</h3>
        <h2>{`I'm a developer from The Netherlands.`}</h2>
        <p>{`I enjoy creating games, audio plugins, websites, mobile apps, and I have a strong passion for proper audio implementation, but most of all; `}</p>
        <p>{`I love learning new things!`}</p>
        <p>{`When I'm not coding, I like to play card games or boulder with friends.`}</p>
        <p>&nbsp;</p>
        {/* <p>{`If you're interested in my audio skills; I have another website!`}</p>
        <a className="link-to-audio" href={"https://www.thomasvraudio.com"} target={"_blank"}>www.thomasvraudio.com</a>  */}
      </div>
      </div>
    </div>
  )
}