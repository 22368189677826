import ProjectDisplayCard from "./ProjectDisplayCard";
import SetVisibility from "../functionality/SetVisiblility";
import Videos from "./Videos";

import { useRef, useState } from "react";

export default function Projects(){
  const [isVisible, setIsVisible] = useState(false);
  const [showVid, setShowVid] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [videoText, setVideoText] = useState("");
  const [videoTitle, setVideoTitle] = useState("");

  const containerRef = useRef(null);

  return (
    <>
    <div id="projects"></div>
    <div className={`projects-video-container ${showVid ? " scroll-to-video" : ""}`}>
      <div className={`video-container${showVid ? "-show-vid" : ""}`}> 
      <Videos showVid={showVid} videoLink={videoLink} videoText={videoText} videoTitle={videoTitle} setShowVid={setShowVid} setVideoLink={setVideoLink}/>
      </div> 
    <div className={`projects-container${showVid ? " show-vid" : ""}`}>

    <SetVisibility containerRef={containerRef} isvisible={isVisible} setIsVisible={setIsVisible} reset={false}/>    

  <h1>{"<Projects />"}</h1>

<div className={`projects`} ref={containerRef}>

<ProjectDisplayCard
    className="projects-item-1"
    title={"Tremolo | Audio Plugin using C++"}
    text={"A tremolo plugin made using the JUCE Framework, with LFO controls to modulate the amount of speed, depth & dry/wet over time."  }
    date={"2023"}
    link={"#projects"} 
    setVideoLink={setVideoLink} 
    videoLink={"https://www.youtube.com/embed/EnvMjhY6R8k"}
    link2={"https://github.com/ThomasVRAudio/Audio-Plugin-Tremolo"}
    linktext={"Github link"}

    isVisible={isVisible}
    setShowVid={setShowVid}
    setVideoText={setVideoText}
    setVideoTitle={setVideoTitle}
    />

  <ProjectDisplayCard
    className="projects-item-2"
    title={"Wizard Defense Game"}
    text={"A Wizard VS hordes of Goblins and Orcs game. Here I used the State Pattern for the enemy behaviour, as well as composition to slot in any type of ISpell"}
    date={"2022"} 
    link={"#projects"} 
    setVideoLink={setVideoLink} 
    videoLink={"https://www.youtube.com/embed/4MAUO6v3D3U"}
    link2={"https://github.com/ThomasVRAudio/Wizard-repo"}
    linktext={"Github link"}

    isVisible={isVisible}
    setShowVid={setShowVid}
    setVideoText={setVideoText}
    setVideoTitle={setVideoTitle}
  />

  <ProjectDisplayCard
    className="projects-item-3"
    title={"GGJ | Indiana Bones & The Coot of all Evil"}
    text={"Our 2023 GGJ Game, where I worked as one of the devs. Here I was in charge of the Player, Abilities, Input System and the Audio Manager "}
    date={"Early 2023"} 
    link={"#projects"} 
    setVideoLink={setVideoLink} 
    videoLink={"https://www.youtube.com/embed/x52xgzKnvGI"}
    link2={"https://josienvos.itch.io/indianabones"}
    linktext={"Itch.io link"}

    isVisible={isVisible}
    setShowVid={setShowVid}
    setVideoText={setVideoText}
    setVideoTitle={setVideoTitle}
  />

<ProjectDisplayCard
    className="projects-item-4"
    title={"Custom EQ and Echoes | Game Audio"}
    text={"A custom EQ for Unity using C++ & an echo system translating distance between reflective surfaces into a slap-back delay, creating outdoor echoes."}
    date={"Early 2023"}
    link={"#projects"} 

    setVideoLink={setVideoLink} 
    videoLink={"https://www.youtube.com/embed/0C9ICNNPAPw"}
    isVisible={isVisible}
    setShowVid={setShowVid}
    setVideoText={setVideoText}
    setVideoTitle={setVideoTitle}
  />

  <ProjectDisplayCard
    className="projects-item-5"
    title={"Wwise Audio Implementation"}
    text={`Created and Implemented audio using Wwise. Added room reverberation, ground checks for footsteps and coupled audio to animation.`}
    date={"\nEarly 2022"}
    link={"#projects"} 
    setVideoLink={setVideoLink} 
    videoLink={"https://www.youtube.com/embed/z5GUetjL9qs"}

    isVisible={isVisible}
    setShowVid={setShowVid}
    setVideoText={setVideoText}
    setVideoTitle={setVideoTitle}
    />

  <ProjectDisplayCard
    className="projects-item-6"
    title={"Perspective | 2D/3D Puzzle Game"}
    text={"A Local 2D/3D Puzzle Game where you play with 3D rotations in a 2D setting, playable as a Singleplayer or Couch Co-Op experience."}
    date={"\nLate 2022"}
    link={"#projects"}
    setVideoLink={setVideoLink}
    videoLink={"https://www.youtube.com/embed/4rVHy040ELU"} 

    isVisible={isVisible}
    setShowVid={setShowVid}
    setVideoText={setVideoText}
    setVideoTitle={setVideoTitle}
    />

</div>
</div>
</div>
</>

  )
}